import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextMediaSplit = makeShortcode("TextMediaSplit");
const ImageBar = makeShortcode("ImageBar");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextMediaSplit imagePath="portfolio/novels-reimagined/novels-reimagined-main.jpg" imageAlt="Novels Reimagined" mdxType="TextMediaSplit">
      <h1>{`Novels Reimagined`}</h1>
      <p>{`Many readers today find it hard to connect with out-of-copyright and older novels due to the barrier of “out-of-date” language, themes and plot-lines. This project focuses on the reimagining of Agatha Christie’s ‘And Then There Were None’ in the hopes of engaging a wider variety of readers and encouraging them to view texts in a different way. `}</p>
      <p>{`Taking inspiration from the late 1930s time period of the novel and its murderous plot-line, I have reimagined the first and last chapter in the form of a police ‘Evidence’ file along with various pieces of ephemera that are included within the text. Once again taking inspiration from the time period, many of these pieces are made with traditional techniques employed at the time - including letterpress and the use of an original typewriter. `}</p>
    </TextMediaSplit>
    <ImageBar imagesDirectory="portfolio/novels-reimagined/lightbox" mdxType="ImageBar" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      